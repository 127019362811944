import React, { useEffect, useState } from "react";
import { Dropdown, DropdownListItem } from "@prequel-internal/react-components";
import { Destination, FormField } from "@prequel/react";
import { Link } from "react-router-dom";

import InputWrapper from "../InputWrapper";
import { useTypedDispatch, useTypedSelector } from "../../../store";
import {
  fetchRecipients,
  selectRecipients,
} from "../../../store/recipients/recipients.duck";
import { ReactComponent as AlertIcon } from "../../../assets/icons/alert-circle.svg";
import { WrapperProps } from "..";
import { SourceFormField } from "../../../store/sources/form";
import { Source } from "../../../store/sources";

const InternalWrapper = <
  F extends FormField | SourceFormField,
  C extends Destination | Source
>({
  field,
  connection,
  setConnectionField,
  disabled,
  isEditing,
}: WrapperProps<F, C>) => {
  const [recipientOptions, setRecipientOptions] =
    useState<DropdownListItem<string>[]>();

  const dispatch = useTypedDispatch();
  const recipients = useTypedSelector(selectRecipients);

  useEffect(() => {
    dispatch(fetchRecipients());
  }, [dispatch]);

  useEffect(() => {
    if (recipients) {
      const options = recipients.map((recipient) => ({
        key: recipient.id,
        text: (
          <span className="flex items-center">
            <span className="mr-1">{recipient.name}</span>
            <span className="text-xs text-gray-500 truncate">
              ({recipient.id_in_provider_system ?? recipient.schema})
            </span>
          </span>
        ),
        products: recipient.products,
      }));

      setRecipientOptions(options);
    }
  }, [recipients]);

  if (field.name === "name" && field.form_element === "input") {
    return (
      <InputWrapper
        field={field as F & { form_element: "input" }}
        connection={connection}
        setConnectionField={setConnectionField}
        disabled={disabled}
        isEditing={isEditing}
      />
    );
  }

  if (field.name === "recipient_id" && "recipient_id" in connection) {
    const selectedRecipient = recipientOptions?.find(
      ({ key }) => key === connection.recipient_id
    );

    return (
      <>
        {recipientOptions && recipientOptions.length ? (
          <>
            <Dropdown
              label={field.label}
              items={recipientOptions ?? []}
              selectedItem={selectedRecipient}
              setSelectedItem={({ key }) =>
                setConnectionField("recipient_id" as keyof C, key)
              }
              disabled={disabled || isEditing}
              subtext="Recipient name (id_in_provider_system)"
            />
          </>
        ) : (
          <div className="flex-col bg-gray-50 border border-gray-200 text-gray-500 rounded p-3 text-sm">
            <div className="flex">
              <AlertIcon
                className="h-5 w-5 text-emerald-600"
                aria-hidden="true"
              />
              <div className="ml-1">
                <p className="text-sm text-gray-900">
                  No configured recipients.
                </p>
                <p className="text-xs">
                  <Link
                    className="text-emerald-600 hover:text-emerald-800"
                    to="/export/recipients/new"
                    target="_blank"
                  >
                    Create a recipient here
                  </Link>{" "}
                  to add your first.
                </p>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }

  // Skip any field that we haven't special-cased
  return <></>;
};

export default InternalWrapper;
