import React, { useMemo } from "react";
import { EVENT_GROUPS, Webhook } from "../../../../store/webhooks";
import EventGroup from "../EventGroup";

type EventGroupsProps = {
  webhook: Webhook;
  handleEventChange: (event: string, isChecked: boolean) => void;
};

const EventGroups: React.FC<EventGroupsProps> = ({
  webhook,
  handleEventChange,
}) => {
  const eventGroups = useMemo(
    () =>
      Object.entries(EVENT_GROUPS).map(([group, events]) => (
        <EventGroup
          key={group}
          group={group}
          events={events}
          webhook={webhook}
          handleEventChange={handleEventChange}
        />
      )),
    [webhook, handleEventChange]
  );

  return <div className="border rounded-md p-4">{eventGroups}</div>;
};

export default EventGroups;
