import React from "react";
import { BrowserRouter as Router, useRoutes, Navigate } from "react-router-dom";

import Layout from "./containers/Layout";
import ToastsFeature from "./containers/ToastsFeature";
import OverviewPage from "./containers/OverviewPage";
import MagicLinksPage from "./containers/MagicLinksPage";
import ModelsPage from "./containers/ModelsPage";
import SourcesPage from "./containers/SourcesPage";
import RecipientsPage from "./containers/RecipientsPage";
import DestinationsPage from "./containers/DestinationsPage";
import WebhooksPage from "./containers/WebhooksPage";

import Authentication from "./containers/Authentication";
import SettingsPage from "./containers/SettingsPage";

const App = () => {
  const element = useRoutes([
    {
      path: "export?/overview/*",
      element: <OverviewPage />,
    },
    {
      path: "export?/sources/*",
      element: <SourcesPage />,
    },
    {
      path: "export?/recipients/*",
      element: <RecipientsPage />,
    },
    {
      path: "export?/destinations/*",
      element: <DestinationsPage />,
    },
    { path: "export?/magic-links/*", element: <MagicLinksPage /> },
    { path: "export?/webhooks/*", element: <WebhooksPage /> },
    { path: "export?/models/*", element: <ModelsPage /> },
    { path: "settings/*", element: <SettingsPage /> },
    { path: "*", element: <Navigate to="export/overview" replace /> },
  ]);
  return <Layout>{element}</Layout>;
};

const AppWrapper = () => {
  return (
    <Router>
      <ToastsFeature />
      <Authentication>
        <App />
      </Authentication>
    </Router>
  );
};

export default AppWrapper;
