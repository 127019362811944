import React, { useMemo } from "react";
import { Webhook } from "../../../../store/webhooks";
import EventCheckbox from "../EventCheckbox";

type EventGroupProps = {
  group: string;
  events: string[];
  webhook: Webhook;
  handleEventChange: (event: string, isChecked: boolean) => void;
};

const EventGroup: React.FC<EventGroupProps> = ({
  group,
  events,
  webhook,
  handleEventChange,
}) => {
  const eventGroup = useMemo(() => {
    return events.map((event) => (
      <EventCheckbox
        key={event}
        event={event}
        checked={webhook.events.includes(event)}
        onChange={handleEventChange}
      />
    ));
  }, [group, events, webhook.events, handleEventChange]);

  return (
    <div className="mb-4">
      <h3 className="text-sm font-medium text-gray-700 mb-2">{group}</h3>
      <div className="pl-4">{eventGroup}</div>
    </div>
  );
};

export default EventGroup;
