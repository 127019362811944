import React, { useEffect } from "react";
import {
  Navigate,
  NavLink,
  useNavigate,
  useParams,
  useRoutes,
} from "react-router-dom";
import {
  Spinner,
  PageHeading,
  Tabs,
  checkTabIsActive,
} from "@prequel-internal/react-components";

import SourceDetails from "../SourceDetails";
import { useTypedDispatch, useTypedSelector } from "../../../store";
import {
  fetchSources,
  selectSource,
} from "../../../store/sources/sources.duck";
import SourceConnectionDetails from "../SourceConnectionDetails";

const SourcePage = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const source = useTypedSelector((state) => selectSource(state, id));

  useEffect(() => {
    dispatch(fetchSources());
  }, [dispatch]);

  const tabs = [
    <NavLink key="General" to="" className={checkTabIsActive} replace end>
      General
    </NavLink>,
    <NavLink
      key="Connection"
      to="connection"
      className={checkTabIsActive}
      replace
      end
    >
      Connection
    </NavLink>,
  ];

  const element = useRoutes([
    {
      path: "/",
      element: <SourceDetails />,
    },
    {
      path: "/connection",
      element: <SourceConnectionDetails />,
    },
    {
      path: "*",
      element: <Navigate to="" replace />,
    },
  ]);

  if (source === undefined) {
    // Still loading connections
    return <Spinner />;
  }

  if (source === null) {
    // Connections loaded, but could not find what we're looking for
    return <Navigate to="/export/sources" replace />;
  }

  return (
    <>
      <PageHeading title={source.name} onGoBack={() => navigate(-1)} />
      <div className="flex-1">
        <div className="relative max-w-4xl mx-auto">
          <div className="pt-0 pb-16">
            <div className="pb-6">
              {/* Tabs */}
              <Tabs tabs={tabs} />
              {element}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SourcePage;
