import React from "react";
import { PageHeading, Spinner } from "@prequel-internal/react-components";
import { useParams, Navigate } from "react-router-dom";
import { useTypedSelector } from "../../../store";
import { selectRecipient } from "../../../store/recipients/recipients.duck";
import RecipientDetails from "../RecipientDetails";

type RecipientPageProps = {
  onGoBack: () => void;
};
const RecipientPage: React.FC<RecipientPageProps> = ({ onGoBack }) => {
  const { id } = useParams<{ id: string }>();
  const recipient = useTypedSelector(selectRecipient(id));

  if (recipient === undefined) {
    // Still loading recipients
    return <Spinner />;
  }

  if (recipient === null) {
    return <Navigate to={"/recipients"} replace />;
  }

  return (
    <>
      <PageHeading title={recipient?.name} onGoBack={onGoBack} />
      <div className="flex-1">
        <div className="relative max-w-4xl mx-auto">
          <div className="pt-0 pb-16">
            <div className="pb-6">
              <RecipientDetails recipient={recipient} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecipientPage;
