import React, { useCallback, useMemo } from "react";
import { Checkbox } from "@prequel-internal/react-components";

type EventCheckboxProps = {
  event: string;
  checked: boolean;
  onChange: (event: string, isChecked: boolean) => void;
};

const EventCheckbox: React.FC<EventCheckboxProps> = ({
  event,
  checked,
  onChange,
}) => {
  const handleChange = useCallback(
    (isChecked: boolean) => onChange(event, isChecked),
    [event, onChange]
  );

  return (
    <Checkbox
      id={event}
      label={event}
      checked={checked}
      setChecked={handleChange}
    />
  );
};

export default EventCheckbox;
