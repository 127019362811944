import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  ButtonStyle,
  Checkbox,
  Form,
  FormField,
} from "@prequel-internal/react-components";

import { computeChangedFields, Recipient } from "../../../store/recipients";
import { useTypedDispatch, useTypedSelector } from "../../../store";
import {
  createRecipient,
  selectRecipient,
  updateRecipient,
} from "../../../store/recipients/recipients.duck";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchProducts,
  selectProducts,
} from "../../../store/products/products.duck";

const RecipientForm = () => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const { id } = useParams<{ id: string }>();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const products = useTypedSelector(selectProducts);
  const recipientToEdit = useTypedSelector(selectRecipient(id));
  const isEditing = !!recipientToEdit;
  const [recipient, setRecipient] = useState<Recipient>({
    name: "",
    schema: "",
    id_in_provider_system: "",
    products: [],
  });
  const [availableProducts, setAvailableProducts] = useState<string[]>();
  const [productsError, setProductsError] = useState<string>();

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  useEffect(() => {
    if (recipientToEdit) {
      setRecipient(recipientToEdit);
    }
  }, [recipientToEdit]);

  useEffect(() => {
    if (products) {
      const opts = products.map(({ product_name }) => product_name);
      setAvailableProducts(opts);
    }
  }, [products]);

  const setField = (key: keyof Recipient, value: string | string[]) => {
    setRecipient((oldRecipient: Recipient) => ({
      ...oldRecipient,
      [key]: value,
    }));
  };

  const updateProducts = (isEnabled: boolean, productName: string) => {
    let updatedProducts: string[] = [];
    if (isEnabled) {
      updatedProducts = [...recipient.products, productName];
    } else {
      updatedProducts = recipient.products.filter((p) => p !== productName);
    }
    setProductsError(undefined);
    setField("products", updatedProducts);
  };

  const onSubmit = () => {
    if (availableProducts && recipient.products.length === 0) {
      setProductsError("Must select at least one product");
      return;
    }

    const action = isEditing
      ? updateRecipient({
          recipientId: recipientToEdit?.id,
          fields: computeChangedFields(recipientToEdit, recipient),
          redirect: () => navigate("/export/recipients"),
        })
      : createRecipient({
          recipient,
          redirect: () => navigate("/export/recipients"),
        });

    dispatch(action);
  };

  return (
    <Form className="space-y-4" onSubmit={onSubmit} submitButtonRef={buttonRef}>
      <FormField
        label="Name"
        id="name"
        type="text"
        subtext="Descriptive name for this Recipient. This will only be visible internally and is only used as a reference."
        value={recipient.name}
        onChangeHandler={(value: string) => setField("name", value)}
        required
      />
      <FormField
        label="ID in Provider System"
        id="id_in_provider_system"
        type="text"
        subtext="Customer ID by which the source data will be filtered. In other words, this is the ID of the target customer in your source database. An engineer or database administrator can provide the unique value for each recipient."
        value={recipient.id_in_provider_system}
        onChangeHandler={(value: string) =>
          setField("id_in_provider_system", value)
        }
        required
        disabled={isEditing}
      />
      {availableProducts && (
        <>
          <label className="block text-sm font-medium text-gray-700">
            Select what products the recipient will receive
          </label>
          {availableProducts.map((p) => (
            <Checkbox
              key={p}
              id={p}
              label={p}
              checked={recipient.products.includes(p)}
              setChecked={(isChecked: boolean) => updateProducts(isChecked, p)}
            />
          ))}
          {productsError && (
            <p className="mt-1 text-xs font-medium text-red-600">
              {productsError}
            </p>
          )}
        </>
      )}
      <div className="flex justify-end mt-8">
        {isEditing && (
          <Button
            className="mr-3"
            type={ButtonStyle.TERTIARY}
            onClick={() => navigate(-1)}
            text="Cancel"
          />
        )}
        <Button
          ref={buttonRef}
          type={ButtonStyle.PRIMARY}
          text={isEditing ? "Update Recipient" : "Create Recipient"}
          submit
        />
      </div>
    </Form>
  );
};

export default RecipientForm;
