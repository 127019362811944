import axios, {
  ApiResponse,
  ApiRoutes,
  AppError,
  ErrorResponse,
  ID_PLACEHOLDER,
  RequestStatus,
} from "../../axios";
import { ExistingModel } from ".";

const getModels: () => Promise<ExistingModel[]> = () => {
  return axios
    .get(ApiRoutes.EXPORT_MODELS)
    .then(
      (response: ApiResponse<{ models: ExistingModel[] }>) =>
        response.data.data.models
    )
    .catch((reason: ErrorResponse) => {
      const e = {
        error: {
          message:
            reason.response?.data?.message || "Failed to fetch model configs.",
          suppressGlobalNotification: true,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const validateModel: (m: ExistingModel) => Promise<RequestStatus> = (model) => {
  const url = ApiRoutes.VALIDATE_EXPORT_MODEL.replace(ID_PLACEHOLDER, model.id);
  return axios
    .post(url)
    .then((response: ApiResponse<RequestStatus>) => response.data.data)
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message:
            reason.response?.data?.message || "Export model validation failed.",
          suppressGlobalNotification: false,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const ModelsService = {
  getModels,
  validateModel,
};

export default ModelsService;
