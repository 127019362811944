import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "@prequel-internal/react-components";
import { Destination, FormField } from "@prequel/react";
import { SourceFormField } from "../../../../store/sources/form";
import { Source } from "../../../../store/sources";
import { WrapperProps } from "../..";

const SSHPublicKey = <
  F extends FormField | SourceFormField,
  C extends Destination | Source,
>({
  field,
  connection,
  setConnectionField,
  isEditing,
}: WrapperProps<F, C>) => {
  const [displayValue, setDisplayValue] = useState<string>();
  const destinationValue = connection[field.name];

  useEffect(() => {
    // Core logical checks to avoid type complaints within the branch: confirms we are looking at the ssh_public_key field and we have the necessary values
    // debugger; // eslint-disable-line
    if (typeof field.const === "string") {
      if (isEditing) {
        // If we are editing and the destination does not have a key, display const and set the key on the destination
        if (!destinationValue) {
          setConnectionField(field.name as keyof C, field.const);
          setDisplayValue(field.const);
        } else {
          // If we are editing and the destination has a key, display it
          setDisplayValue(destinationValue.toString());
        }
      } else {
        // If we're not editing, display the key and set it on the destination
        setConnectionField(field.name as keyof C, field.const);
        setDisplayValue(field.const);
      }
    }
  }, [field.const]);

  return (
    <>
      <div className="w-full shadow-sm inline-flex rounded-md pl-3 pr-1 py-2 mt-1 text-gray-500 text-sm bg-gray-50 border border-gray-300 font-mono">
        <div className="w-full truncate mr-3">{displayValue ?? ""}</div>
        <CopyToClipboard textToCopy={displayValue ?? ""} />
      </div>
      {field.description && (
        <p
          className="mt-2 text-xs text-gray-400 whitespace-pre-line"
          id="description"
        >
          {field.description}
        </p>
      )}
    </>
  );
};

export default SSHPublicKey;
