import { createSlice, CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { all, put, takeEvery } from "redux-saga/effects";
import { ExistingWebhook, Webhook } from ".";
import WebhookService from "./webhooks.service";
import {
  RootState,
  createWorkerSaga,
  createRedirectSaga,
  WithRedirect,
} from "..";
import { AppError } from "../../axios";
import { createToast } from "../toasts/toasts.duck";

type WebhooksState = {
  webhooks: ExistingWebhook[] | undefined;
};

const initialState: WebhooksState = {
  webhooks: undefined,
};

const fetchWebhooksReducer: CaseReducer<WebhooksState, PayloadAction<void>> = (
  state
) => state;

const fetchWebhooksSuccessReducer: CaseReducer<
  WebhooksState,
  PayloadAction<ExistingWebhook[]>
> = (state, action) => {
  state.webhooks = action.payload;
};

const fetchWebhooksFailureReducer: CaseReducer<
  WebhooksState,
  PayloadAction<AppError>
> = (state) => state;

const createWebhookReducer: CaseReducer<
  WebhooksState,
  PayloadAction<WithRedirect<{ webhook: Webhook }>>
> = (state) => state;

const createWebhookSuccessReducer: CaseReducer<
  WebhooksState,
  PayloadAction<WithRedirect<{ webhook: ExistingWebhook }>>
> = (state) => state;

const createWebhookFailureReducer: CaseReducer<
  WebhooksState,
  PayloadAction<AppError>
> = (state) => state;

const updateWebhookReducer: CaseReducer<
  WebhooksState,
  PayloadAction<WithRedirect<{ webhookId: string; webhook: Partial<Webhook> }>>
> = (state) => state;

const updateWebhookSuccessReducer: CaseReducer<
  WebhooksState,
  PayloadAction<WithRedirect<{ webhook: ExistingWebhook }>>
> = (state, action) => {
  const updatedWebhook = action.payload.webhook;
  state.webhooks = state.webhooks?.map((w) =>
    w.id === updatedWebhook.id ? { ...w, ...updatedWebhook } : w
  );
};

const updateWebhookFailureReducer: CaseReducer<
  WebhooksState,
  PayloadAction<AppError>
> = (state) => state;

const deleteWebhookReducer: CaseReducer<
  WebhooksState,
  PayloadAction<
    WithRedirect<{
      webhookId: ExistingWebhook["id"];
    }>
  >
> = (state) => state;

const deleteWebhookSuccessReducer: CaseReducer<
  WebhooksState,
  PayloadAction<WithRedirect<{}>>
> = (state) => state;

const deleteWebhookFailureReducer: CaseReducer<
  WebhooksState,
  PayloadAction<AppError>
> = (state) => state;

function* watchFetchWebhooks() {
  yield takeEvery(
    fetchWebhooks.type,
    createWorkerSaga(
      fetchWebhooks,
      fetchWebhooksSuccess,
      fetchWebhooksFailure,
      WebhookService.getWebhooks
    )
  );
}

function* watchCreateWebhook() {
  yield takeEvery(
    createWebhook.type,
    createWorkerSaga(
      createWebhook,
      createWebhookSuccess,
      createWebhookFailure,
      WebhookService.postWebhook
    )
  );
}

function* watchCreateWebhookSuccess() {
  yield takeEvery(createWebhookSuccess.type, function* () {
    yield put(fetchWebhooks());
    yield put(
      createToast({
        toast: {
          id: new Date().getTime().toString(),
          kind: "SUCCESS",
          message: "Webhook created successfully!",
        },
      })
    );
  });
  yield takeEvery(createWebhookSuccess.type, createRedirectSaga());
}

function* watchDeleteWebhook() {
  yield takeEvery(
    deleteWebhook.type,
    createWorkerSaga(
      deleteWebhook,
      deleteWebhookSuccess,
      deleteWebhookFailure,
      WebhookService.deleteWebhook
    )
  );
}

function* watchDeleteWebhookSuccess() {
  yield takeEvery(deleteWebhookSuccess.type, function* () {
    yield put(fetchWebhooks());
  });
  yield takeEvery(deleteWebhookSuccess.type, createRedirectSaga());
}

function* watchUpdateWebhook() {
  yield takeEvery(
    updateWebhook.type,
    createWorkerSaga(
      updateWebhook,
      updateWebhookSuccess,
      updateWebhookFailure,
      WebhookService.patchWebhook
    )
  );
}

function* watchUpdateWebhookSuccess() {
  yield takeEvery(updateWebhookSuccess.type, function* () {
    yield put(fetchWebhooks());
    yield put(
      createToast({
        toast: {
          id: new Date().getTime().toString(),
          kind: "SUCCESS",
          message: "Webhook updated successfully!",
        },
      })
    );
  });
  yield takeEvery(updateWebhookSuccess.type, createRedirectSaga());
}

const webhooksSlice = createSlice({
  name: "webhooks",
  initialState,
  reducers: {
    fetchWebhooks: fetchWebhooksReducer,
    fetchWebhooksSuccess: fetchWebhooksSuccessReducer,
    fetchWebhooksFailure: fetchWebhooksFailureReducer,
    createWebhook: createWebhookReducer,
    createWebhookSuccess: createWebhookSuccessReducer,
    createWebhookFailure: createWebhookFailureReducer,
    updateWebhook: updateWebhookReducer,
    updateWebhookSuccess: updateWebhookSuccessReducer,
    updateWebhookFailure: updateWebhookFailureReducer,
    deleteWebhook: deleteWebhookReducer,
    deleteWebhookSuccess: deleteWebhookSuccessReducer,
    deleteWebhookFailure: deleteWebhookFailureReducer,
  },
});

export const {
  fetchWebhooks,
  fetchWebhooksSuccess,
  fetchWebhooksFailure,
  createWebhook,
  createWebhookSuccess,
  createWebhookFailure,
  updateWebhook,
  updateWebhookSuccess,
  updateWebhookFailure,
  deleteWebhook,
  deleteWebhookSuccess,
  deleteWebhookFailure,
} = webhooksSlice.actions;

export const selectWebhooks = ({ webhooks }: RootState) => webhooks.webhooks;
export const selectWebhook = (webhookId: string | undefined) => {
  return ({ webhooks }: RootState) => {
    return webhookId
      ? webhooks.webhooks?.find((webhook) => webhook.id === webhookId) ?? null
      : undefined;
  };
};

export function* webhooksSaga() {
  yield all([
    watchFetchWebhooks(),
    watchCreateWebhook(),
    watchCreateWebhookSuccess(),
    watchDeleteWebhook(),
    watchDeleteWebhookSuccess(),
    watchUpdateWebhook(),
    watchUpdateWebhookSuccess(),
  ]);
}

export default webhooksSlice.reducer;
