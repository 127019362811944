import axios, { ApiResponse, ApiRoutes, ErrorResponse } from "../../axios";
import { ExistingWebhook, Webhook } from ".";
import { WithRedirect } from "..";

const handleError = (
  defaultMessage: string,
  errResponse: ErrorResponse,
  suppressGlobalNotification: boolean
) => {
  throw {
    error: {
      message: errResponse.response?.data?.message || defaultMessage,
      suppressGlobalNotification,
      statusCode: errResponse.response?.status,
    },
  };
};

const getWebhooks: () => Promise<ExistingWebhook[]> = () => {
  return axios
    .get(ApiRoutes.WEBHOOKS)
    .then((response: ApiResponse<{ webhooks: ExistingWebhook[] }>) =>
      response.data.data.webhooks.map((webhook) => ({
        ...webhook,
        delivery_method: webhook.vendor,
      }))
    )
    .catch((errResponse: ErrorResponse) =>
      handleError("Failed to fetch webhooks.", errResponse, true)
    );
};

const postWebhook: ({
  webhook,
  redirect,
}: WithRedirect<{ webhook: Webhook }>) => Promise<
  WithRedirect<{ webhook: ExistingWebhook }>
> = ({ webhook, redirect }) => {
  return axios
    .post(ApiRoutes.WEBHOOKS, { webhook: webhook })
    .then((response: ApiResponse<{ webhook: ExistingWebhook }>) => ({
      webhook: response.data.data.webhook,
      redirect,
    }))
    .catch((errResponse: ErrorResponse) =>
      handleError("Failed to create webhook.", errResponse, false)
    );
};

const deleteWebhook: ({
  webhookId,
  redirect,
}: WithRedirect<{ webhookId: ExistingWebhook["id"] }>) => Promise<
  WithRedirect<{}>
> = ({ webhookId, redirect }) => {
  return axios
    .delete(`${ApiRoutes.WEBHOOKS}/${webhookId}`)
    .then(() => ({ redirect }))
    .catch((errResponse: ErrorResponse) =>
      handleError("Failed to delete webhook.", errResponse, false)
    );
};

const patchWebhook: ({
  webhookId,
  webhook,
  redirect,
}: WithRedirect<{
  webhookId: ExistingWebhook["id"];
  webhook: Partial<Webhook>;
}>) => Promise<WithRedirect<{ webhook: ExistingWebhook }>> = ({
  webhookId,
  webhook,
  redirect,
}) => {
  return axios
    .patch(`${ApiRoutes.WEBHOOKS}/${webhookId}`, { webhook: webhook })
    .then((response: ApiResponse<{ webhook: ExistingWebhook }>) => ({
      webhook: response.data.data.webhook,
      redirect,
    }))
    .catch((errResponse: ErrorResponse) =>
      handleError("Failed to update webhook.", errResponse, false)
    );
};

const WebhookService = {
  getWebhooks,
  postWebhook,
  patchWebhook,
  deleteWebhook,
};

export default WebhookService;
