import React, { useEffect, useState } from "react";
import { Destination, FormField } from "@prequel/react";
import {
  Checkbox,
  RadioCardOption,
  RadioCards,
  SectionExpandable,
} from "@prequel-internal/react-components";

import { useTypedSelector } from "../../../../store";
import { selectOrg } from "../../../../store/org/org.duck";
import WrapperSelector from "../../../WrapperSelector";

type AdvancedOptionsSectionProps = {
  fields: FormField[];
  destination: Destination;
  setDestinationField: (
    key: keyof Destination,
    value: string | boolean | string[] | number
  ) => void;
  disabled: boolean;
  isEditing: boolean;
};

const AdvancedOptionsSection = ({
  fields,
  destination,
  setDestinationField,
  disabled,
  isEditing,
}: AdvancedOptionsSectionProps) => {
  const org = useTypedSelector(selectOrg);
  const [frequencyOptions, setFrequencyOptions] =
    useState<RadioCardOption<number>[]>();
  const [orgDefault, setOrgDefault] = useState<RadioCardOption<number>>();

  useEffect(() => {
    const frequencyField = fields.find(
      (field) => field.name === "frequency_minutes"
    );
    if (frequencyField && frequencyField.form_element === "radio") {
      const items: RadioCardOption<number>[] = frequencyField.enum
        .filter(({ key }) => key !== 0)
        .map((e) => ({
          value: parseInt(e.key.toString()),
          label: e.display,
          enabled: !e.disabled,
        }));

      setFrequencyOptions(items);
      const def = items.find(
        ({ value }) => value === org?.default_frequency_minutes
      );
      setOrgDefault(def);
    }
  }, [fields, org]);

  return (
    <SectionExpandable label="Advanced options">
      {fields.map((field) => {
        if (field.name === "frequency_minutes") {
          return (
            <div key={field.name} className="mb-6">
              <div className="flex items-center justify-between">
                <h2 className={"text-sm font-medium text-gray-900 mb-3"}>
                  Sync Frequency
                </h2>
              </div>
              <Checkbox
                key="use_default_frequency"
                id="use_default_frequency"
                label={
                  <span>
                    Use default frequency
                    {orgDefault ? (
                      <span className="text-gray-400 font-normal">
                        {" "}
                        ({orgDefault.label})
                      </span>
                    ) : (
                      ""
                    )}
                  </span>
                }
                checked={destination.frequency_minutes === 0}
                setChecked={(isChecked: boolean) =>
                  isChecked
                    ? setDestinationField("frequency_minutes", 0)
                    : setDestinationField(
                        "frequency_minutes",
                        org?.default_frequency_minutes ?? 1440
                      )
                }
                disabled={disabled}
              />
              <RadioCards
                options={frequencyOptions ?? []}
                selectedOption={frequencyOptions?.find(
                  ({ value }) => value === destination.frequency_minutes
                )}
                setSelectedOption={({ value }) =>
                  setDestinationField("frequency_minutes", value)
                }
                disabled={disabled || destination.frequency_minutes === 0}
              />
            </div>
          );
        }

        return (
          <div key={field.name} className="mb-6">
            <WrapperSelector
              field={field}
              connection={destination}
              setConnectionField={setDestinationField}
              disabled={disabled}
              isEditing={isEditing}
            />
          </div>
        );
      })}
    </SectionExpandable>
  );
};

export default AdvancedOptionsSection;
