import React, { useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, ConfirmModal } from "@prequel-internal/react-components";
import { useTypedDispatch, useTypedSelector } from "../../../store";
import {
  deleteWebhook,
  selectWebhook,
} from "../../../store/webhooks/webhooks.duck";
import WebhookDetailsSection from "./WebhookDetailsSection";
import DangerZoneSection from "./DangerZoneSection";

const WebhookDetails: React.FC = () => {
  const { webhookId } = useParams<{ webhookId: string }>();
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const webhook = useTypedSelector(selectWebhook(webhookId));

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleDeleteConfirm = useCallback(() => {
    if (webhook) {
      dispatch(
        deleteWebhook({
          webhookId: webhook.id,
          redirect: () => navigate("/webhooks"),
        })
      );
    }
    setIsDeleteModalOpen(false);
  }, [dispatch, navigate, webhook]);

  const handleEditClick = useCallback(() => {
    if (webhook) {
      navigate(`/webhooks/${webhook.id}/edit`);
    }
  }, [navigate, webhook]);

  if (!webhook) {
    return null;
  }

  return (
    <>
      <Modal
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
      >
        <ConfirmModal
          title="Delete webhook"
          message="Are you sure you want to delete this webhook? This action cannot be undone."
          onConfirm={handleDeleteConfirm}
          confirmButtonText="Delete"
          onCancel={() => setIsDeleteModalOpen(false)}
        />
      </Modal>
      <WebhookDetailsSection webhook={webhook} />
      <DangerZoneSection
        onEditClick={handleEditClick}
        onDeleteClick={() => setIsDeleteModalOpen(true)}
      />
    </>
  );
};

export default WebhookDetails;
