import React, { useEffect, useRef, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Form,
  Button,
  ButtonStyle,
  FormField,
} from "@prequel-internal/react-components";
import { useTypedDispatch, useTypedSelector } from "../../../store";
import {
  createWebhook,
  updateWebhook,
  selectWebhook,
  fetchWebhooks,
} from "../../../store/webhooks/webhooks.duck";
import {
  computeChangedFields,
  DELIVERY_METHOD_OPTIONS,
  Webhook,
} from "../../../store/webhooks";
import EventGroups from "./EventGroups";
import DeliveryMethodAndUrl from "./DeliveryMethodAndUrl";

const initialWebhookState: Webhook = {
  name: "",
  vendor: DELIVERY_METHOD_OPTIONS[0].key,
  url: "",
  api_version: "2023-10-15",
  events: [],
};

const WebhookForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const { webhookId } = useParams<{ webhookId: string }>();
  const webhookToEdit = useTypedSelector(selectWebhook(webhookId));
  const isEditing = !!webhookToEdit;

  const buttonRef = useRef<HTMLButtonElement>(null);
  const [webhook, setWebhook] = useState<Webhook>(initialWebhookState);

  const setWebhookField = useCallback(
    (key: keyof Webhook, value: string | string[]) => {
      setWebhook((prevWebhook) => ({
        ...prevWebhook,
        [key]: value,
      }));
    },
    []
  );

  const handleEventChange = useCallback((event: string, isChecked: boolean) => {
    setWebhook((prevWebhook) => {
      const updatedEvents = isChecked
        ? prevWebhook.events.includes(event)
          ? prevWebhook.events
          : [...prevWebhook.events, event]
        : prevWebhook.events.filter((e) => e !== event);

      return { ...prevWebhook, events: updatedEvents };
    });
  }, []);

  const onDeliveryMethodChange = useCallback(
    (deliveryMethod: { key: string }) => {
      setWebhook((prevWebhook) => ({
        ...prevWebhook,
        vendor: deliveryMethod.key,
      }));
    },
    []
  );

  useEffect(() => {
    if (webhookId && !webhookToEdit) {
      dispatch(fetchWebhooks());
    }

    if (webhookToEdit) {
      setWebhook(webhookToEdit);
    }
  }, [webhookId, webhookToEdit, dispatch]);

  const onSubmit = useCallback(() => {
    const action = isEditing
      ? updateWebhook({
          webhookId: webhookToEdit!.id,
          webhook: computeChangedFields(webhookToEdit!, webhook),
          redirect: () => navigate("/webhooks"),
        })
      : createWebhook({
          webhook,
          redirect: () => navigate("/webhooks"),
        });

    dispatch(action);
  }, [isEditing, webhookToEdit, webhook, dispatch, navigate]);

  return (
    <div className="pb-16">
      <Form
        className="space-y-4"
        onSubmit={onSubmit}
        submitButtonRef={buttonRef}
      >
        <FormField
          label="Name"
          id="name"
          type="text"
          subtext="Descriptive name for this webhook."
          value={webhook.name}
          onChangeHandler={(value) => setWebhookField("name", value)}
          required
        />
        <DeliveryMethodAndUrl
          webhook={webhook}
          setWebhookField={setWebhookField}
          onDeliveryMethodChange={onDeliveryMethodChange}
        />
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Events
          </label>
          <EventGroups
            webhook={webhook}
            handleEventChange={handleEventChange}
          />
        </div>
        <div className="flex justify-end mt-8">
          {isEditing && (
            <Button
              className="mr-3"
              type={ButtonStyle.TERTIARY}
              onClick={() => navigate(-1)}
              text="Cancel"
            />
          )}
          <Button
            type={ButtonStyle.PRIMARY}
            ref={buttonRef}
            text={isEditing ? "Update Webhook" : "Create Webhook"}
            submit
          />
        </div>
      </Form>
    </div>
  );
};

export default WebhookForm;
