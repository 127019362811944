import React, { useState, useEffect, useRef, useMemo } from "react";
import { EnumObject } from "@prequel/react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  ButtonStyle,
  Form,
  FormField,
  Dropdown,
  DropdownListItem,
  Checkbox,
  RadioButtons,
  RadioOption,
} from "@prequel-internal/react-components";

import VendorLogo from "../../../components/VendorLogo";
import { useTypedDispatch, useTypedSelector } from "../../../store";
import { DropdownListItemWithFields, MagicLinkVendor } from "../../../lib";
import {
  createMagicLink,
  fetchMagicLinkForm,
  selectMagicLinkForm,
} from "../../../store/magic_links/magic_links.duck";
import { FormMagicLink, prepareMagicLink } from "../../../store/magic_links";
import ConfirmDataDestinationModal from "../../ConfirmDataDestinationModal";
import AdvancedOptions from "./AdvancedOptions";
import {
  fetchRecipients,
  selectRecipients,
} from "../../../store/recipients/recipients.duck";
import { DestinationTenanting } from "../../../store/destinations";
import ExistingRecipient from "../../../store/recipients";
import {
  fetchProducts,
  selectProducts,
} from "../../../store/products/products.duck";

type DropdownListItemWithProducts = DropdownListItem<string> & {
  products: string[];
};
const MagicLinkForm = () => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const recipients = useTypedSelector(selectRecipients);
  const productConfigs = useTypedSelector(selectProducts);
  const magicLinkForm = useTypedSelector(selectMagicLinkForm);

  // We have to track this by index because the radioOptions have to get re-rendered every time
  const [selectedRadioOptionIdx, setSelectedRadioOptionIdx] =
    useState<DestinationTenanting>(DestinationTenanting.BY_ID);

  const [dropdownVendors, setDropdownVendors] =
    useState<DropdownListItem<string>[]>();
  const [selectedItem, setSelectedItem] = useState<DropdownListItemWithFields>({
    key: "",
    text: "",
    fields: [],
    docs: "",
    uses_service_account: false,
    uses_staging_bucket: false,
    supports_ssh_tunnel: false,
  });
  const [link, setLink] = useState<FormMagicLink>({
    name: "",
    id_in_provider_system: "",
    recipient_id: "",
    host: "",
    bucket_name: "",
    bucket_vendor: "",
    bucket_access_id: "",
    vendor: selectedItem.key,
    products: [],
    set_destination_enabled: true,
    redirect_url: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [products, setProducts] = useState<string[]>();
  const [productsError, setProductsError] = useState<string>();
  const [selectedRecipient, setSelectedRecipient] =
    useState<DropdownListItemWithProducts>({ key: "", text: "", products: [] });
  const [recipientOptions, setRecipientOptions] =
    useState<DropdownListItem<string>[]>();

  useEffect(() => {
    dispatch(fetchProducts());
    dispatch(fetchRecipients());
    dispatch(fetchMagicLinkForm());
  }, [dispatch]);

  // We create this as a constant so it gets re-rendered every time and the values get updated in each section
  const radioOptions = [
    {
      key: "id_in_provider_system",
      name: "ID in Provider System",
      content: (
        <FormField
          id="id_in_provider_system"
          type="text"
          subtext="Customer ID by which the source data will be filtered. In other words, this is the ID of the target customer in your source database. An engineer or database administrator can provide the unique value for each destination."
          value={link.id_in_provider_system}
          onChangeHandler={(value: string) => {
            setLink((oldLink) => ({
              ...oldLink,
              id_in_provider_system: value,
            }));
          }}
          required
        />
      ),
    },
    {
      key: "recipient_id",
      name: "Recipient",
      disabled: !recipientOptions || recipientOptions.length === 0,
      content: (
        <>
          <Dropdown
            items={recipientOptions ?? []}
            selectedItem={selectedRecipient}
            setSelectedItem={setSelectedRecipient}
            subtext="Recipient name (id_in_provider_system)"
          />
        </>
      ),
    },
  ];

  const metastoreOptions = [
    { key: "unity_catalog", text: "Unity Catalog" },
    { key: "hive", text: "Hive" },
  ];

  const requiresHost = useMemo(() => {
    return !!selectedItem.fields.find(
      (f) => f.name === "host" && f.is_required
    );
  }, [selectedItem]);

  const usesObjectStorageToWrite = useMemo(() => {
    return !!selectedItem.fields.find(
      (f) =>
        f.name === "bucket_name" &&
        (f.is_required || selectedItem.key === "databricks")
    );
  }, [selectedItem]);

  // these vendor-specific references are temporary - will be replaced by form built off magic links jsonschema endpoint
  const requiresBucketVendor = useMemo(
    () => link.vendor === "clickhouse",
    [link]
  );

  const requiresMetastore = useMemo(() => link.vendor === "databricks", [link]);

  const requiresBucketAccessId = useMemo(
    () => link.vendor === "abs" || link.bucket_vendor === "abs",
    [link]
  );

  const selectedRecipientObject = useMemo(
    () => recipients?.find(({ id }) => id === link.recipient_id),
    [link.recipient_id]
  );

  const updateSelectedProducts = (isEnabled: boolean, productName: string) => {
    let updatedProducts: string[] = [];
    if (isEnabled) {
      updatedProducts = [...link.products, productName];
    } else {
      updatedProducts = link.products.filter((p) => p !== productName);
    }
    setLinkField("products", updatedProducts);
  };

  const setLinkField = (
    key: keyof FormMagicLink,
    value: string | string[] | boolean
  ) => {
    setLink((oldLink) => ({
      ...oldLink,
      [key]: value,
    }));
  };

  useEffect(() => {
    if (recipients) {
      const options = recipients.map((recipient: ExistingRecipient) => ({
        key: recipient.id,
        text: (
          <span className="flex items-center">
            <span className="mr-1">{recipient.name}</span>
            <span className="text-xs text-gray-500 truncate">
              ({recipient.id_in_provider_system ?? recipient.schema})
            </span>
          </span>
        ),
        products: recipient.products,
      }));

      setRecipientOptions(options);
      setSelectedRecipient(options[0]);
    }
  }, [recipients]);

  useEffect(() => {
    if (selectedRecipient) {
      setLinkField("recipient_id", selectedRecipient.key);
    }
  }, [selectedRecipient]);

  useEffect(() => {
    if (magicLinkForm && !dropdownVendors) {
      const options = magicLinkForm.map((m: MagicLinkVendor) => {
        const icon = () => <VendorLogo logo_url={m.logo_url} />;
        return {
          ...m,
          key: m.vendor_name,
          text: m.display_name,
          icon,
        };
      });
      setDropdownVendors(options);
      setSelectedItem(options[0]);
    }
  }, [magicLinkForm]);

  useEffect(() => {
    if (productConfigs) {
      const opts = productConfigs.map(({ product_name }) => product_name);
      setProducts(opts);
      // pre-select the default product if it exists
      setLinkField(
        "products",
        opts.find((o) => o === "default") ? ["default"] : []
      );
    }
  }, [productConfigs]);

  useEffect(() => {
    setProductsError(undefined);
  }, [link.products]);

  useEffect(() => {
    if (link.metastore === "hive") {
      setLinkField("bucket_vendor", "s3");
    } else {
      setLinkField("bucket_vendor", "");
    }
  }, [link.metastore]);

  useEffect(() => {
    setLinkField("vendor", selectedItem.key);
    // Reset bucket_name and metastore on vendor change
    setLinkField("bucket_name", "");
    setLinkField("metastore", "");

    if (selectedItem.key === "databricks") {
      // Form defaults to unity_catalog for databricks
      setLinkField("metastore", "unity_catalog");
    }
  }, [selectedItem]);

  const confirm = () => {
    setShowModal(false);
    const preparedLink = prepareMagicLink(
      link,
      selectedRadioOptionIdx === DestinationTenanting.BY_ID
    );

    dispatch(
      createMagicLink({
        link: preparedLink,
        redirect: () => navigate("/export/magic-links"),
      })
    );
  };

  const onSubmit = (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    // If there are products to select and we have not picked one, prevent creation
    if (
      products &&
      link.products.length == 0 &&
      selectedRadioOptionIdx === DestinationTenanting.BY_ID
    ) {
      setProductsError("Must select at least one product");
      return;
    }

    setShowModal(true);
  };

  const buttonRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <ConfirmDataDestinationModal
        kind="GENERATE_LINK"
        show={showModal}
        setShow={setShowModal}
        dataIdentifier={
          selectedRadioOptionIdx === DestinationTenanting.BY_ID
            ? link.id_in_provider_system
            : selectedRecipientObject
            ? selectedRecipientObject.name
            : ""
        }
        dataDestination={link}
        onConfirm={confirm}
      />
      <Form
        className="space-y-4"
        onSubmit={onSubmit}
        submitButtonRef={buttonRef}
      >
        {dropdownVendors && (
          <Dropdown
            label={"Destination type"}
            items={dropdownVendors}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
          />
        )}
        <FormField
          label="Name"
          id="name"
          type="text"
          subtext="Descriptive name for this Magic Link. This will only be visible internally and is only used as a reference."
          value={link.name}
          onChangeHandler={(value: string) => setLinkField("name", value)}
          required
        />
        <RadioButtons
          label="Specify the recipient"
          options={radioOptions}
          selectedOption={radioOptions[selectedRadioOptionIdx]}
          setSelectedOption={(selected: RadioOption) =>
            setSelectedRadioOptionIdx(
              selected.name === "Recipient"
                ? DestinationTenanting.BY_RECIPIENT
                : DestinationTenanting.BY_ID
            )
          }
        />
        {requiresHost && (
          <FormField
            label="Host of Destination"
            id="host"
            type="text"
            subtext="Host address of the destination database. The owner of the destination (your customer) should provide this value."
            value={link.host}
            onChangeHandler={(value: string) => setLinkField("host", value)}
            required
          />
        )}
        {requiresMetastore && (
          <Dropdown
            label="Metastore"
            items={metastoreOptions}
            selectedItem={metastoreOptions.find(
              ({ key }) => key === link.metastore
            )}
            setSelectedItem={({ key }) => setLinkField("metastore", key)}
          />
        )}
        {requiresBucketVendor && link.metastore !== "unity_catalog" && (
          <FormField
            label="Bucket Vendor"
            id="bucket_vendor"
            type="text"
            subtext="Vendor of storage bucket to use. The owner of the destination (your customer) should provide this value."
            value={link.bucket_vendor}
            onChangeHandler={(value: string) =>
              setLinkField("bucket_vendor", value)
            }
            required
          />
        )}
        {usesObjectStorageToWrite && link.metastore !== "unity_catalog" && (
          <FormField
            label="Bucket Name"
            id="bucket_name"
            type="text"
            subtext="Name of storage bucket to use. The owner of the destination (your customer) should provide this value."
            value={link.bucket_name}
            onChangeHandler={(value: string) =>
              setLinkField("bucket_name", value)
            }
            required
          />
        )}
        {requiresBucketAccessId && (
          <FormField
            label="Bucket Access ID"
            id="bucket_access_id"
            type="text"
            subtext="Azure Blob Storage Access ID. The owner of the destination (your customer) should provide this value."
            value={link.bucket_access_id}
            onChangeHandler={(value: string) =>
              setLinkField("bucket_access_id", value)
            }
            required
          />
        )}
        {products && selectedRadioOptionIdx === DestinationTenanting.BY_ID && (
          <>
            <div className="h-px w-full bg-gray-200"></div> {/* Divider  */}
            <label className="block text-sm font-medium text-gray-700">
              Select what products the destination will receive
            </label>
            {products.map((p) => (
              <Checkbox
                key={p}
                id={p}
                label={p}
                checked={link.products.includes(p)}
                setChecked={(isChecked: boolean) =>
                  updateSelectedProducts(isChecked, p)
                }
              />
            ))}
            {productsError && (
              <p className="mt-1 text-xs font-medium text-red-600">
                {productsError}
              </p>
            )}
          </>
        )}
        <AdvancedOptions link={link} setLinkField={setLinkField} />
        <div>
          <Button
            className="mt-6"
            submit
            text="Generate Link"
            type={ButtonStyle.PRIMARY}
            ref={buttonRef}
          />
        </div>
      </Form>
    </>
  );
};

export default MagicLinkForm;
