import { createSlice, CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { all, takeEvery, put } from "redux-saga/effects";
import { EnumObject } from "@prequel/react";

import { AppError, RequestStatus } from "../../axios";
import ExistingSource, {
  ExistingSourceTestPayload,
  PreparedSource,
  SourceTest,
  SSHPublicKey,
  SSHPublicKeyResponse,
} from ".";
import { SourceForm } from "./form";
import SourcesService from "./sources.service";
import {
  RootState,
  createWorkerSaga,
  createRedirectSaga,
  WithRedirect,
} from "..";

// Slice state
type SourcesState = {
  sources: ExistingSource[] | undefined;
  sourceTest: SourceTest;
  sourceServiceAccount: string | undefined;
  sourceVendors: EnumObject[] | undefined;
  form: SourceForm | undefined;
  sshPublicKey: SSHPublicKey | undefined;
};
const initialState: SourcesState = {
  sources: undefined,
  sourceTest: { status: undefined },
  sourceServiceAccount: undefined,
  sourceVendors: undefined,
  form: undefined,
  sshPublicKey: undefined,
};

// Action Reducers (Case Reducers)
const fetchSourcesReducer: CaseReducer<SourcesState, PayloadAction<void>> = (
  state: SourcesState
) => state;

const fetchSourcesSuccessReducer: CaseReducer<
  SourcesState,
  PayloadAction<ExistingSource[]>
> = (state: SourcesState, action: PayloadAction<ExistingSource[]>) => {
  state.sources = action.payload;
};

const fetchSourcesFailureReducer: CaseReducer<
  SourcesState,
  PayloadAction<AppError>
> = (state: SourcesState) => state;

const testNewSourceReducer: CaseReducer<
  SourcesState,
  PayloadAction<PreparedSource>
> = (state: SourcesState) => {
  state.sourceTest.status = "processing";
};

const testNewSourceSuccessReducer: CaseReducer<
  SourcesState,
  PayloadAction<RequestStatus>
> = (state: SourcesState) => {
  state.sourceTest.status = "success";
};

const testNewSourceFailureReducer: CaseReducer<
  SourcesState,
  PayloadAction<AppError>
> = (state: SourcesState, action: PayloadAction<AppError>) => {
  state.sourceTest = {
    status: "error",
    message: action.payload.error.message,
  };
};

const createSourceReducer: CaseReducer<
  SourcesState,
  PayloadAction<WithRedirect<{ source: PreparedSource }>>
> = (state: SourcesState) => state;

const createSourceSuccessReducer: CaseReducer<
  SourcesState,
  PayloadAction<WithRedirect<{ source: ExistingSource }>>
> = (state: SourcesState) => state;

const createSourceFailureReducer: CaseReducer<
  SourcesState,
  PayloadAction<AppError>
> = (state: SourcesState) => state;

const updateSourceReducer: CaseReducer<
  SourcesState,
  PayloadAction<
    WithRedirect<
      WithRedirect<{
        sourceId: ExistingSource["id"];
        source: Partial<PreparedSource>;
      }>
    >
  >
> = (state: SourcesState) => state;

const updateSourceSuccessReducer: CaseReducer<
  SourcesState,
  PayloadAction<WithRedirect<{ source: ExistingSource }>>
> = (
  state: SourcesState,
  action: PayloadAction<{ source: ExistingSource }>
) => {
  const updatedSource = action.payload.source;
  state.sources = state.sources?.map((d) =>
    d.id === updatedSource.id ? { ...d, ...updatedSource } : d
  );
};

const updateSourceFailureReducer: CaseReducer<
  SourcesState,
  PayloadAction<AppError>
> = (state: SourcesState) => state;

const deleteSourceReducer: CaseReducer<
  SourcesState,
  PayloadAction<
    WithRedirect<{
      sourceId: ExistingSource["id"];
    }>
  >
> = (state: SourcesState) => state;

const deleteSourceSuccessReducer: CaseReducer<
  SourcesState,
  PayloadAction<WithRedirect<{}>>
> = (state: SourcesState) => state;

const deleteSourceFailureReducer: CaseReducer<
  SourcesState,
  PayloadAction<AppError>
> = (state: SourcesState) => state;

const testExistingSourceReducer: CaseReducer<
  SourcesState,
  PayloadAction<ExistingSourceTestPayload>
> = (state) => {
  state.sourceTest.status = "processing";
};

const testExistingSourceSuccessReducer: CaseReducer<
  SourcesState,
  PayloadAction<RequestStatus>
> = (state) => {
  state.sourceTest.status = "success";
};

const testExistingSourceFailureReducer: CaseReducer<
  SourcesState,
  PayloadAction<AppError>
> = (state, action) => {
  state.sourceTest = {
    status: "error",
    message: action.payload.error.message,
  };
};

const resetSourceTestReducer: CaseReducer<SourcesState, PayloadAction<void>> = (
  state: SourcesState
) => {
  state.sourceTest.status = undefined;
};

const fetchSourceServiceAccountReducer: CaseReducer<
  SourcesState,
  PayloadAction<void>
> = (state: SourcesState) => state;

const fetchSourceServiceAccountSuccessReducer: CaseReducer<
  SourcesState,
  PayloadAction<string>
> = (state: SourcesState, action: PayloadAction<string>) => {
  state.sourceServiceAccount = action.payload;
};

const fetchSourceServiceAccountFailureReducer: CaseReducer<
  SourcesState,
  PayloadAction<AppError>
> = (state: SourcesState) => state;

const fetchSourceFormReducer: CaseReducer<
  SourcesState,
  PayloadAction<string | undefined>
> = (state: SourcesState) => state;

const fetchSourceFormSuccessReducer: CaseReducer<
  SourcesState,
  PayloadAction<SourceForm>
> = (state: SourcesState, action: PayloadAction<SourceForm>) => {
  state.form = action.payload;
};

const fetchSourceFormFailureReducer: CaseReducer<
  SourcesState,
  PayloadAction<AppError>
> = (state: SourcesState) => state;

const fetchSourceVendorsReducer: CaseReducer<
  SourcesState,
  PayloadAction<void>
> = (state: SourcesState) => state;

const fetchSourceVendorsSuccessReducer: CaseReducer<
  SourcesState,
  PayloadAction<EnumObject[]>
> = (state: SourcesState, action: PayloadAction<EnumObject[]>) => {
  state.sourceVendors = action.payload;
};

const fetchSourceVendorsFailureReducer: CaseReducer<
  SourcesState,
  PayloadAction<AppError>
> = (state: SourcesState) => state;

const fetchSSHPublicKeyReducer: CaseReducer<
  SourcesState,
  PayloadAction<void>
> = (state) => state;

const fetchSSHPublicKeySuccessReducer: CaseReducer<
  SourcesState,
  PayloadAction<SSHPublicKeyResponse>
> = (state, action) => {
  state.sshPublicKey = {
    ssh_rsa: action.payload.public_key,
    pkcs8: action.payload.public_key_pkcs8,
  };
};

const fetchSSHPublicKeyFailureReducer: CaseReducer<
  SourcesState,
  PayloadAction<AppError>
> = (state) => state;

const clearSSHPublicKeyReducer: CaseReducer<
  SourcesState,
  PayloadAction<void>
> = (state: SourcesState) => {
  state.sshPublicKey = undefined;
};

function* watchFetchSources() {
  yield takeEvery(
    fetchSources.type,
    createWorkerSaga(
      fetchSources,
      fetchSourcesSuccess,
      fetchSourcesFailure,
      SourcesService.getSources
    )
  );
}

function* watchTestNewSource() {
  yield takeEvery(
    testNewSource.type,
    createWorkerSaga(
      testNewSource,
      testNewSourceSuccess,
      testNewSourceFailure,
      SourcesService.testNewSource
    )
  );
}

function* watchCreateSource() {
  yield takeEvery(
    createSource.type,
    createWorkerSaga(
      createSource,
      createSourceSuccess,
      createSourceFailure,
      SourcesService.postSource
    )
  );
}

function* watchUpdateSource() {
  yield takeEvery(
    updateSource.type,
    createWorkerSaga(
      updateSource,
      updateSourceSuccess,
      updateSourceFailure,
      SourcesService.patchSource
    )
  );
}

function* watchDeleteSource() {
  yield takeEvery(
    deleteSource.type,
    createWorkerSaga(
      deleteSource,
      deleteSourceSuccess,
      deleteSourceFailure,
      SourcesService.deleteSource
    )
  );
}

function* watchFetchSourceVendors() {
  yield takeEvery(
    fetchSourceVendors.type,
    createWorkerSaga(
      fetchSourceVendors,
      fetchSourceVendorsSuccess,
      fetchSourceVendorsFailure,
      SourcesService.getSourceVendors
    )
  );
}

function* watchFetchSourceForm() {
  yield takeEvery(
    fetchSourceForm.type,
    createWorkerSaga(
      fetchSourceForm,
      fetchSourceFormSuccess,
      fetchSourceFormFailure,
      SourcesService.getSourceForm
    )
  );
}

function* watchCreateSourceSuccess() {
  yield takeEvery(createSourceSuccess.type, function* () {
    yield put(fetchSources());
  });
  yield takeEvery(createSourceSuccess.type, createRedirectSaga());
}

function* watchUpdateSourceSuccess() {
  yield takeEvery(updateSourceSuccess.type, function* () {
    yield put(fetchSources());
  });
  yield takeEvery(updateSourceSuccess.type, createRedirectSaga());
}

function* watchDeleteSourceSuccess() {
  yield takeEvery(deleteSourceSuccess.type, function* () {
    yield put(fetchSources());
  });
  yield takeEvery(deleteSourceSuccess.type, createRedirectSaga());
}

function* watchTestExistingSource() {
  yield takeEvery(
    testExistingSource.type,
    createWorkerSaga(
      testExistingSource,
      testExistingSourceSuccess,
      testExistingSourceFailure,
      SourcesService.testExistingSource
    )
  );
}

function* watchFetchSSHPublicKey() {
  yield takeEvery(
    fetchSSHPublicKey.type,
    createWorkerSaga(
      fetchSSHPublicKey,
      fetchSSHPublicKeySuccess,
      fetchSSHPublicKeyFailure,
      SourcesService.generateSourcePublicKey
    )
  );
}

const sourcesSlice = createSlice({
  name: "sources",
  initialState,
  reducers: {
    fetchSources: fetchSourcesReducer,
    fetchSourcesSuccess: fetchSourcesSuccessReducer,
    fetchSourcesFailure: fetchSourcesFailureReducer,
    testNewSource: testNewSourceReducer,
    testNewSourceSuccess: testNewSourceSuccessReducer,
    testNewSourceFailure: testNewSourceFailureReducer,
    resetSourceTest: resetSourceTestReducer,
    createSource: createSourceReducer,
    createSourceSuccess: createSourceSuccessReducer,
    createSourceFailure: createSourceFailureReducer,
    updateSource: updateSourceReducer,
    updateSourceSuccess: updateSourceSuccessReducer,
    updateSourceFailure: updateSourceFailureReducer,
    deleteSource: deleteSourceReducer,
    deleteSourceSuccess: deleteSourceSuccessReducer,
    deleteSourceFailure: deleteSourceFailureReducer,
    fetchSourceServiceAccount: fetchSourceServiceAccountReducer,
    fetchSourceServiceAccountSuccess: fetchSourceServiceAccountSuccessReducer,
    fetchSourceServiceAccountFailure: fetchSourceServiceAccountFailureReducer,
    fetchSourceVendors: fetchSourceVendorsReducer,
    fetchSourceVendorsSuccess: fetchSourceVendorsSuccessReducer,
    fetchSourceVendorsFailure: fetchSourceVendorsFailureReducer,
    fetchSourceForm: fetchSourceFormReducer,
    fetchSourceFormSuccess: fetchSourceFormSuccessReducer,
    fetchSourceFormFailure: fetchSourceFormFailureReducer,
    testExistingSource: testExistingSourceReducer,
    testExistingSourceSuccess: testExistingSourceSuccessReducer,
    testExistingSourceFailure: testExistingSourceFailureReducer,
    fetchSSHPublicKey: fetchSSHPublicKeyReducer,
    fetchSSHPublicKeySuccess: fetchSSHPublicKeySuccessReducer,
    fetchSSHPublicKeyFailure: fetchSSHPublicKeyFailureReducer,
  },
});

export const {
  fetchSources,
  fetchSourcesSuccess,
  fetchSourcesFailure,
  testNewSource,
  testNewSourceSuccess,
  testNewSourceFailure,
  resetSourceTest,
  createSource,
  createSourceSuccess,
  createSourceFailure,
  updateSource,
  updateSourceSuccess,
  updateSourceFailure,
  deleteSource,
  deleteSourceSuccess,
  deleteSourceFailure,
  fetchSourceServiceAccount,
  fetchSourceServiceAccountSuccess,
  fetchSourceServiceAccountFailure,
  fetchSourceVendors,
  fetchSourceVendorsSuccess,
  fetchSourceVendorsFailure,
  fetchSourceForm,
  fetchSourceFormSuccess,
  fetchSourceFormFailure,
  testExistingSource,
  testExistingSourceSuccess,
  testExistingSourceFailure,
  fetchSSHPublicKey,
  fetchSSHPublicKeySuccess,
  fetchSSHPublicKeyFailure,
} = sourcesSlice.actions;

export const selectSources: (state: RootState) => SourcesState["sources"] = ({
  sources,
}: RootState) => sources.sources;
export const selectSource: (
  state: RootState,
  id?: string
) => ExistingSource | null | undefined = (state, sourceId) => {
  const sources = selectSources(state);
  if (sources === undefined) {
    // If we don't have sources, return undefined as we need to wait for fetch
    return undefined;
  }

  const found = sources.find(({ id }) => id === sourceId);
  // If we could not find the source, return null to signify it is not in the loaded sources
  return found ?? null;
};
export const selectSourceTest: (
  state: RootState
) => SourcesState["sourceTest"] = ({ sources }: RootState) =>
  sources.sourceTest;
export const selectSourceServiceAccount: (
  state: RootState
) => SourcesState["sourceServiceAccount"] = ({ sources }: RootState) =>
  sources.sourceServiceAccount;
export const selectSourceForm: (state: RootState) => SourcesState["form"] = ({
  sources,
}: RootState) => sources.form;
export const selectSourceVendors: (
  state: RootState
) => SourcesState["sourceVendors"] = ({ sources }: RootState) =>
  sources.sourceVendors;
export const selectSourceSSHPublicKey: (
  state: RootState
) => SourcesState["sshPublicKey"] = ({ sources }) => sources.sshPublicKey;

export function* sourcesSaga() {
  yield all([
    watchFetchSources(),
    watchTestNewSource(),
    watchCreateSource(),
    watchCreateSourceSuccess(),
    watchUpdateSource(),
    watchUpdateSourceSuccess(),
    watchDeleteSource(),
    watchDeleteSourceSuccess(),
    watchFetchSourceVendors(),
    watchFetchSourceForm(),
    watchTestExistingSource(),
    watchFetchSSHPublicKey(),
  ]);
}
export default sourcesSlice.reducer;
