import React, { useMemo } from "react";
import { PasswordDot, TextArea } from "@prequel-internal/react-components";
import {
  Destination,
  FormField,
  parseGcpIamRoleMetadata,
  parseServiceAccountKey,
} from "@prequel/react";
import { SourceFormField } from "../../../store/sources/form";
import { Source } from "../../../store/sources";
import { WrapperProps } from "..";

type TextAreaWrapperProps<
  F extends FormField | SourceFormField,
  C extends Destination | Source
> = WrapperProps<F & { form_element: "textarea" }, C>;
const TextAreaWrapper = <
  F extends FormField | SourceFormField,
  C extends Destination | Source
>({
  field,
  connection,
  setConnectionField,
  disabled,
  isEditing,
}: TextAreaWrapperProps<F, C>) => {
  const serviceAccountKeyIsValid = useMemo(
    () => !!parseServiceAccountKey(connection.service_account_key),
    [connection.service_account_key]
  );

  const gcpIamRoleMetadataIsValid = useMemo(
    () =>
      !!parseGcpIamRoleMetadata(
        connection.workload_identity_federation_metadata
      ),
    [connection.workload_identity_federation_metadata]
  );

  return (
    <TextArea
      id={field.name}
      label={field.label}
      placeholder={isEditing ? PasswordDot.repeat(184) : field.placeholder}
      overwriteOnly={isEditing}
      subtext={field.description}
      value={connection[field.name]?.toString()}
      onChangeHandler={(value: string) => {
        setConnectionField(field.name as keyof C, value);
      }}
      invalid={
        field.name === "service_account_key"
          ? !serviceAccountKeyIsValid
          : field.name === "workload_identity_federation_metadata"
          ? !gcpIamRoleMetadataIsValid
          : false
      }
      required={field.required && !isEditing}
      disabled={disabled}
    />
  );
};

export default TextAreaWrapper;
