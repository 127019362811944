import { RequestStatus } from "../../axios";

export interface Recipient {
  name: string;
  schema: string;
  id_in_provider_system: string;
  products: string[];
}

export default interface ExistingRecipient extends Recipient {
  id: string;
  created_at: string;
  updated_at: string;
}

export type UpdateRecipientPayload = {
  recipientId: ExistingRecipient["id"];
  fields?: Partial<Recipient>;
};

export interface SourceCredential {
  source_id: string;
  recipient_id: string;
  username: string;
  password: string;
}

export interface ExistingSourceCredential extends SourceCredential {
  id: string;
}

export type SourceCredentialTest = {
  status: RequestStatus;
  message?: string;
};

export type RecipientRequest = {
  status: RequestStatus;
  message?: string;
};

export const computeChangedFields = (
  existing: ExistingRecipient,
  updated: Recipient
): Partial<Recipient> => {
  const changedFields: Partial<Recipient> = {};

  (Object.keys(updated) as Array<keyof Recipient>).forEach((key) => {
    if (existing[key] !== updated[key]) {
      changedFields[key] = updated[key] as any;
    }
  });

  return changedFields;
};
