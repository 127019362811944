import React, { useState, useCallback, useRef, useMemo } from "react";
import { FormField, Dropdown } from "@prequel-internal/react-components";
import {
  DELIVERY_METHOD_OPTIONS,
  styleDeliveryMethod,
  URL_PLACEHOLDERS,
  Webhook,
} from "../../../../store/webhooks";

const STYLED_DELIVERY_METHOD_OPTIONS = DELIVERY_METHOD_OPTIONS.map(
  (option) => ({
    ...option,
    text: styleDeliveryMethod(option.text, option.key),
  })
);

type DeliveryMethodAndUrlProps = {
  webhook: Webhook;
  setWebhookField: (key: keyof Webhook, value: string) => void;
  onDeliveryMethodChange: (deliveryMethod: {
    key: string;
    text: string | JSX.Element;
  }) => void;
};

const DeliveryMethodAndUrl: React.FC<DeliveryMethodAndUrlProps> = ({
  webhook,
  setWebhookField,
  onDeliveryMethodChange,
}) => {
  const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState<{
    key: string;
    text: string | JSX.Element;
  }>(STYLED_DELIVERY_METHOD_OPTIONS[0]);
  const originalUrlRef = useRef(webhook.url);

  const urlPlaceholder = useMemo(
    () =>
      URL_PLACEHOLDERS[
        selectedDeliveryMethod.key as keyof typeof URL_PLACEHOLDERS
      ] || URL_PLACEHOLDERS.default,
    [selectedDeliveryMethod.key]
  );

  const urlSubtext = useMemo(
    () =>
      selectedDeliveryMethod.key === "pagerduty"
        ? "The URL to send webhook events to. PagerDuty has fixed URL that cannot be modified."
        : "The URL to send webhook events to.",
    [selectedDeliveryMethod.key]
  );

  const handleDeliveryMethodChange = useCallback(
    (deliveryMethod: { key: string; text: string | JSX.Element }) => {
      setSelectedDeliveryMethod(deliveryMethod);
      onDeliveryMethodChange(deliveryMethod);
      setWebhookField("vendor", deliveryMethod.key);

      if (deliveryMethod.key === "pagerduty") {
        originalUrlRef.current = webhook.url;
        setWebhookField("url", URL_PLACEHOLDERS.pagerduty);
      } else if (selectedDeliveryMethod.key === "pagerduty") {
        setWebhookField("url", originalUrlRef.current);
      }
    },
    [
      onDeliveryMethodChange,
      setWebhookField,
      webhook.url,
      selectedDeliveryMethod.key,
    ]
  );

  return (
    <>
      <Dropdown
        label="Delivery Method"
        items={STYLED_DELIVERY_METHOD_OPTIONS}
        selectedItem={selectedDeliveryMethod}
        setSelectedItem={handleDeliveryMethodChange}
        subtext="Delivery method (vendor)"
      />
      <FormField
        label="URL"
        id="url"
        type="url"
        placeholder={urlPlaceholder}
        subtext={urlSubtext}
        value={webhook.url}
        onChangeHandler={(value) => setWebhookField("url", value)}
        required
        readOnly={selectedDeliveryMethod.key === "pagerduty"}
      />
    </>
  );
};

export default DeliveryMethodAndUrl;
