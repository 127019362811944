import React from "react";

export interface Webhook {
  name: string;
  vendor: string;
  url: string;
  api_version: string;
  events: string[];
}

export interface ExistingWebhook extends Webhook {
  id: string;
  created_at: string;
  updated_at: string;
}

export const computeChangedFields = (
  existingWebhook: ExistingWebhook,
  updatedWebhook: Webhook
): Partial<Webhook> => {
  const changedFields: Partial<Webhook> = {};

  (Object.keys(updatedWebhook) as Array<keyof Webhook>).forEach((key) => {
    if (existingWebhook[key] !== updatedWebhook[key]) {
      changedFields[key] = updatedWebhook[key] as any;
    }
  });

  return changedFields;
};

export const styleDeliveryMethod = (text: string, key: string) => (
  <span className="flex items-center">
    <span className="mr-1">{text}</span>
    <span className="text-xs text-gray-500 truncate">
      (<span className="text-xs text-gray-400">{key}</span>)
    </span>
  </span>
);

export const DELIVERY_METHOD_OPTIONS = [
  { key: "generic_post", text: "HTTP POST" },
  { key: "generic_get", text: "HTTP GET" },
  { key: "slack", text: "Slack" },
  { key: "pagerduty", text: "PagerDuty" },
];

export const EVENT_GROUPS = {
  Transfer: ["transfer.error"],
  Source: [
    "export_source.created",
    "export_source.updated",
    "export_source.deleted",
  ],
  Destination: [
    "export_destination.created",
    "export_destination.updated",
    "export_destination.deleted",
  ],
  Recipient: ["recipient.created", "recipient.updated", "recipient.deleted"],
  "Magic Link": [
    "export_magic_link.created",
    "export_magic_link.updated",
    "export_magic_link.deleted",
  ],
};

export const URL_PLACEHOLDERS = {
  slack: "https://hooks.slack.com/services/YOUR_WEBHOOK_URL",
  pagerduty: "https://events.pagerduty.com/v2/enqueue",
  default: "https://example.com/webhook",
};
