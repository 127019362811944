import React, { useState } from "react";
import {
  DescriptionList,
  Button,
  ButtonStyle,
  Modal,
  ConfirmModal,
} from "@prequel-internal/react-components";
import Timestamp from "../../../components/Timestamp";
import ExistingRecipient from "../../../store/recipients";
import { useNavigate } from "react-router-dom";
import { useTypedDispatch } from "../../../store";
import { deleteRecipient } from "../../../store/recipients/recipients.duck";

type RecipientDetailsProps = {
  recipient: ExistingRecipient;
};
const RecipientDetails: React.FC<RecipientDetailsProps> = ({ recipient }) => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const onDelete = () => {
    setShowDeleteModal(true);
  };

  const onConfirmDelete = () => {
    setShowDeleteModal(false);
    dispatch(
      deleteRecipient({
        recipientId: recipient.id,
        redirect: () => navigate("/export/recipients"),
      })
    );
  };

  return (
    <>
      <Modal open={showDeleteModal} onClose={() => setShowDeleteModal(false)}>
        <ConfirmModal
          title="Delete recipient"
          message="Are you sure you want to delete this recipient? This action cannot be undone."
          onConfirm={onConfirmDelete}
          confirmButtonText="Delete"
          onCancel={() => setShowDeleteModal(false)}
        />
      </Modal>
      <div className="mt-10 divide-y divide-gray-200">
        <div className="space-y-1">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Details
          </h3>
        </div>
        <div className="mt-6">
          <DescriptionList>
            <DescriptionList.Item label="ID" value={recipient.id} />
            <DescriptionList.Item label="Name" value={recipient.name} />
            <DescriptionList.Item
              label="ID in Provider System"
              value={recipient.id_in_provider_system}
            />
            <DescriptionList.Item
              label="Products"
              value={recipient.products.join(", ")}
            />
            <DescriptionList.Item
              label="Created At"
              value={<Timestamp timestamp={recipient.created_at} />}
            />
            <DescriptionList.Item
              label="Updated At"
              value={<Timestamp timestamp={recipient.updated_at} />}
            />
          </DescriptionList>
        </div>
      </div>
      <div className="mt-10 divide-y divide-red-300">
        <div className="space-y-1">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Danger Zone
          </h3>
          <p className="max-w-2xl text-sm text-gray-500">
            Actions in this section should be considered carefully.
          </p>
        </div>
        <div className="mt-6">
          <DescriptionList type="danger">
            <DescriptionList.ButtonItem
              label="Edit recipient"
              description={"Edit recipient fields."}
            >
              <Button
                text="Edit Recipient"
                type={ButtonStyle.DANGER}
                onClick={() =>
                  navigate(`/export/recipients/${recipient.id}/edit`)
                }
              />
            </DescriptionList.ButtonItem>
            <DescriptionList.ButtonItem
              label="Delete recipient"
              description="Once you delete a recipient, the action cannot be undone."
            >
              <Button
                text="Delete Recipient"
                type={ButtonStyle.DANGER}
                onClick={onDelete}
              />
            </DescriptionList.ButtonItem>
          </DescriptionList>
        </div>
      </div>
    </>
  );
};

export default RecipientDetails;
