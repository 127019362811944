import React, { useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Table, TableRow } from "@prequel-internal/react-components";
import { useTypedDispatch, useTypedSelector } from "../../../store";
import {
  fetchWebhooks,
  selectWebhooks,
} from "../../../store/webhooks/webhooks.duck";
import {
  DELIVERY_METHOD_OPTIONS,
  ExistingWebhook,
} from "../../../store/webhooks";
import Timestamp from "../../../components/Timestamp";

const WebhooksTable: React.FC = () => {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const webhooks = useTypedSelector(selectWebhooks);

  useEffect(() => {
    dispatch(fetchWebhooks());
  }, [dispatch]);

  const columns = ["Name", "Delivery Method", "URL", "Events", "Created At"];
  const rows: TableRow[] = useMemo(() => {
    if (!webhooks) {
      return [];
    }
    return webhooks.map((webhook: ExistingWebhook) => {
      const deliveryMethod =
        DELIVERY_METHOD_OPTIONS.find((option) => option.key === webhook.vendor)
          ?.text || webhook.vendor;

      return {
        id: webhook.id,
        cells: [
          { value: webhook.name },
          { value: deliveryMethod },
          { value: webhook.url },
          { value: webhook.events.join(", ") },
          { value: <Timestamp timestamp={webhook.created_at} /> },
        ],
      };
    });
  }, [webhooks]);

  const onRowClick = useCallback(
    (row: TableRow) => {
      navigate(`/webhooks/${row.id}`);
    },
    [navigate]
  );

  return (
    <Table
      columns={columns}
      rows={rows}
      footer={`${rows.length} webhooks`}
      onRowClick={onRowClick}
    />
  );
};

export default WebhooksTable;
