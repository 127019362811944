import React, { useMemo } from "react";
import { Dropdown } from "@prequel-internal/react-components";
import { Destination, FormField } from "@prequel/react";

import VendorLogo from "../../../components/VendorLogo";
import { WrapperProps } from "..";
import { SourceFormField } from "../../../store/sources/form";
import { Source } from "../../../store/sources";

type SelectWrapperProps<
  F extends FormField | SourceFormField,
  C extends Destination | Source
> = WrapperProps<F & { form_element: "select" }, C>;
const SelectWrapper = <
  F extends FormField | SourceFormField,
  C extends Destination | Source
>({
  field,
  connection,
  setConnectionField,
  disabled,
  isEditing,
}: SelectWrapperProps<F, C>) => {
  const items = useMemo(
    () =>
      field.enum?.map((e) => ({
        key: e.key.toString(),
        text: e.display,
        ...(e.icon_url !== undefined
          ? { icon: () => <VendorLogo logo_url={e.icon_url ?? ""} /> }
          : {}),
      })),
    [field.enum]
  );

  const selected = useMemo(
    () => items?.find(({ key }) => key === connection[field.name]),
    [items, connection[field.name]]
  );

  return (
    <Dropdown
      label={field.label}
      items={items ?? []}
      selectedItem={selected}
      setSelectedItem={(item) =>
        setConnectionField(field.name as keyof C, item.key)
      }
      disabled={disabled || (field.name === "vendor" && isEditing)}
    />
  );
};

export default SelectWrapper;
