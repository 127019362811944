import React from "react";
import { DescriptionList } from "@prequel-internal/react-components";
import Timestamp from "../../../../components/Timestamp";
import {
  DELIVERY_METHOD_OPTIONS,
  ExistingWebhook,
  styleDeliveryMethod,
} from "../../../../store/webhooks";

type WebhookDetailsSectionProps = {
  webhook: ExistingWebhook;
};

const WebhookDetailsSection: React.FC<WebhookDetailsSectionProps> = ({
  webhook,
}) => {
  return (
    <>
      <SectionHeader />
      <SectionContent webhook={webhook} />
    </>
  );
};

const SectionHeader: React.FC = () => (
  <div className="space-y-1">
    <h3 className="text-lg leading-6 font-medium text-gray-900">Details</h3>
  </div>
);

const SectionContent: React.FC<WebhookDetailsSectionProps> = ({ webhook }) => (
  <div className="mt-6">
    <DescriptionList>
      <DescriptionList.Item label="ID" value={webhook.id} />
      <DescriptionList.Item label="Name" value={webhook.name} />
      <DescriptionList.Item
        label="Delivery Method"
        value={getDeliveryMethodText(webhook.vendor)}
      />
      <DescriptionList.Item label="URL" value={webhook.url} />
      <DescriptionList.Item label="Events" value={webhook.events.join(", ")} />
      <DescriptionList.Item
        label="Created At"
        value={<Timestamp timestamp={webhook.created_at} />}
      />
      <DescriptionList.Item
        label="Updated At"
        value={<Timestamp timestamp={webhook.updated_at} />}
      />
    </DescriptionList>
  </div>
);

const getDeliveryMethodText = (vendor: string) => {
  const deliveryMethodOption = DELIVERY_METHOD_OPTIONS.find(
    (option) => option.key === vendor
  );

  return deliveryMethodOption
    ? styleDeliveryMethod(deliveryMethodOption.text, vendor)
    : vendor;
};

export default WebhookDetailsSection;
