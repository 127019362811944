import React from "react";
import {
  Badge,
  BadgeColor,
  Table,
  TableRow,
} from "@prequel-internal/react-components";

import ExistingRecipient from "../../../store/recipients";
import { useNavigate } from "react-router-dom";

type RecipientsTableProps = {
  recipients: ExistingRecipient[] | undefined;
  sourcesSchemaTenanted: boolean;
};
const RecipientsTable = ({
  recipients,
  sourcesSchemaTenanted,
}: RecipientsTableProps) => {
  const navigate = useNavigate();

  const rows = [...(recipients || [])].map((recipient) => ({
    id: recipient.id,
    cells: [
      {
        value: recipient.name,
      },
      {
        value: recipient.id_in_provider_system,
      },
      {
        value: (
          <>
            {recipient.products.map((p) => (
              <Badge
                key={p}
                className="mr-1"
                text={p}
                color={BadgeColor.GRAY}
              />
            ))}
          </>
        ),
      },
    ],
  }));

  const columns = ["Recipient Name", "ID in Provider System", "Products"];

  const onRowClick = (row: TableRow) => navigate(`${row.id}`);

  return (
    <Table
      columns={columns}
      rows={rows}
      footer={rows ? `${rows.length} recipients` : ""}
      onRowClick={onRowClick}
    />
  );
};

export default RecipientsTable;
