import React from "react";
import {
  DescriptionList,
  Button,
  ButtonStyle,
} from "@prequel-internal/react-components";

interface DangerZoneSectionProps {
  onEditClick: () => void;
  onDeleteClick: () => void;
}

const DangerZoneSection: React.FC<DangerZoneSectionProps> = ({
  onEditClick,
  onDeleteClick,
}) => {
  const dangerActions = [
    {
      label: "Edit webhook",
      description: "Edit webhook fields.",
      buttonText: "Edit webhook",
      onClick: onEditClick,
    },
    {
      label: "Delete webhook",
      description: "Once you delete a webhook, the action cannot be undone.",
      buttonText: "Delete webhook",
      onClick: onDeleteClick,
    },
  ];

  return (
    <section className="mt-10 divide-y divide-red-300">
      <header className="space-y-1">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Danger Zone
        </h3>
        <p className="max-w-2xl text-sm text-gray-500">
          Actions in this section should be considered carefully.
        </p>
      </header>
      <div className="mt-6">
        <DescriptionList type="danger">
          {dangerActions.map(({ label, description, buttonText, onClick }) => (
            <DescriptionList.ButtonItem
              key={label}
              label={label}
              description={description}
            >
              <Button
                text={buttonText}
                type={ButtonStyle.DANGER}
                onClick={onClick}
              />
            </DescriptionList.ButtonItem>
          ))}
        </DescriptionList>
      </div>
    </section>
  );
};

export default DangerZoneSection;
