import React, { useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import { Spinner, PageHeading } from "@prequel-internal/react-components";

import WebhookDetails from "../WebhookDetails";
import { useTypedDispatch, useTypedSelector } from "../../../store";
import {
  fetchWebhooks,
  selectWebhook,
} from "../../../store/webhooks/webhooks.duck";

type WebhookPageProps = {
  onGoBack: () => void;
};

const WebhookPage: React.FC<WebhookPageProps> = ({ onGoBack }) => {
  const { webhookId } = useParams<{ webhookId: string }>();
  const dispatch = useTypedDispatch();
  const webhook = useTypedSelector(selectWebhook(webhookId));

  useEffect(() => {
    dispatch(fetchWebhooks());
  }, [dispatch]);

  if (webhook === undefined) {
    return <Spinner />;
  }

  if (webhook === null) {
    return <Navigate to="/webhooks" replace />;
  }

  return (
    <>
      <PageHeading title={webhook.name} onGoBack={onGoBack} />
      <div className="flex-1">
        <div className="relative max-w-4xl mx-auto">
          <div className="pt-0 pb-16">
            <div className="pb-6">
              <WebhookDetails />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WebhookPage;
