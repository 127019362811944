import React from "react";
import { NavLink, useNavigate, useRoutes } from "react-router-dom";
import {
  Button,
  ButtonStyle,
  Heading,
  PageHeading,
  Tabs,
  checkTabIsActive,
} from "@prequel-internal/react-components";

import WebhooksTable from "./WebhooksTable";
import WebhookForm from "./WebhookForm";
import WebhookPage from "./WebhookPage";

const WebhooksPage: React.FC = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/webhooks");
  };

  // Memoize the tabs array to prevent unnecessary re-renders
  const tabs = React.useMemo(
    () => [
      <NavLink key="Summary" to="#" className={checkTabIsActive}>
        Summary
      </NavLink>,
    ],
    []
  );

  // Memoize the routes configuration
  const routeConfig = React.useMemo(
    () => [
      {
        path: "/",
        element: (
          <div className="max-w-6xl py-4 pb-16 px-7">
            <PageHeading title="Webhooks">
              <Button
                text="Add Webhook"
                onClick={() => navigate("new")}
                type={ButtonStyle.SECONDARY}
              />
            </PageHeading>
            <Tabs tabs={tabs} />
            <WebhooksTable />
          </div>
        ),
      },
      {
        path: "new",
        element: (
          <div className="max-w-2xl py-4 px-7">
            <PageHeading title="Add Webhook" onGoBack={handleGoBack} />
            <WebhookForm />
          </div>
        ),
      },
      {
        path: ":webhookId/edit",
        element: (
          <div className="max-w-2xl py-4 px-7">
            <PageHeading title="Edit Webhook" onGoBack={handleGoBack} />
            <WebhookForm />
          </div>
        ),
      },
      {
        path: ":webhookId/*",
        element: (
          <div className="max-w-4xl py-4 px-7">
            <WebhookPage onGoBack={handleGoBack} />
          </div>
        ),
      },
    ],
    [navigate, handleGoBack]
  );

  const elements = useRoutes(routeConfig);

  return <div className="flex-col h-full">{elements}</div>;
};

export default WebhooksPage;
