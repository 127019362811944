import React from "react";
import {
  parseServiceAccountKey,
  ServiceAccountKey,
  ModelConfigColumn,
  AuthMethod,
  BucketVendor,
  Metastore,
  GcpExternalAccountMetadata,
  parseGcpIamRoleMetadata,
} from "@prequel/react";
import { RequestStatus } from "../../axios";
import {
  AthenaSourceResource,
  BigQuerySourceResource,
  ClickhouseSourceResource,
  DatabricksSourceResource,
  MongoDBSourceResource,
  MySQLSourceResource,
  OracleSourceResource,
  PostgresSourceResource,
  RedshiftServerlessSourceResource,
  RedshiftSourceResource,
  SnowflakeSourceResource,
} from "./resources";
import { Form, SourceFormField, SourceFormNode } from "./form";

// Using this enum for the type values of the source interfaces
// allows us easier type detection and to create discriminated unions
// for us in multi-purpose components like SourceDetails
export enum SourceType {
  Source,
  PreparedSource,
  ExistingSource,
}

export type SSHPublicKeyResponse = {
  public_key: string;
  public_key_pkcs8: string;
};

type KeyFormat = SourceFormNode["attributes"]["key_format"];
export type SSHPublicKey = {
  [K in NonNullable<KeyFormat>]: string;
};

export interface Source {
  readonly type: SourceType.Source;
  name: string;
  vendor: string;
  access_id?: string;
  access_key?: string;
  access_token?: string;
  authentication_database?: string;
  auth_method?: AuthMethod;
  aws_iam_role?: string;
  aws_iam_role_arn?: string;
  bucket_access_id?: string;
  bucket_name?: string;
  bucket_region?: string;
  bucket_secret_key?: string;
  bucket_vendor?: BucketVendor;
  cluster?: string;
  connection_database?: string;
  database?: string;
  disable_ssl?: boolean;
  error_code?: string;
  gcp_iam_role?: string;
  host?: string;
  http_path?: string;
  metastore?: Metastore;
  password?: string;
  port?: string;
  project_id?: string;
  public_key?: string;
  secret_key?: string;
  service_account_email?: string;
  service_account_key?: string;
  username?: string;
  use_ssh_tunnel?: boolean;
  ssh_public_key?: string;
  ssh_tunnel_host?: string;
  ssh_tunnel_port?: string;
  ssh_tunnel_username?: string;
  wallet?: string;
  workgroup?: string;
  workload_identity_federation_metadata?: string;
}

export interface PreparedSource {
  readonly type: SourceType.PreparedSource;
  name: string;
  vendor: string;
  athena?: AthenaSourceResource;
  aurora_mysql?: MySQLSourceResource;
  aurora_postgres?: PostgresSourceResource;
  bigquery?: BigQuerySourceResource;
  clickhouse?: ClickhouseSourceResource;
  databricks?: DatabricksSourceResource;
  mongodb?: MongoDBSourceResource;
  mysql?: MySQLSourceResource;
  oracle?: OracleSourceResource;
  postgres?: PostgresSourceResource;
  redshift?: RedshiftSourceResource;
  redshift_serverless?: RedshiftServerlessSourceResource;
  singlestore?: MySQLSourceResource;
  snowflake?: SnowflakeSourceResource;
  sql_server?: MySQLSourceResource;
}

export default interface ExistingSource extends Omit<PreparedSource, "type"> {
  readonly type: SourceType.ExistingSource;
  readonly id: string;
  readonly created_at: string;
  readonly updated_at: string;
}

export type SourceTest = {
  status: RequestStatus;
  message?: string;
};

export type ModelMapping = {
  model_name: string;
  source_table_name: string;
  description: string;
  mappings: ModelConfigColumn[];
};

export type ExistingSourceTestPayload = {
  sourceId: ExistingSource["id"];
  fields?: Partial<PreparedSource>;
};

export const getHostOrBucketName = (s: ExistingSource) => {
  if (s.vendor === "athena") {
    return s.athena?.bucket_s3.bucket_name;
  } else if (s.vendor === "bigquery") {
    return s.bigquery?.project_id;
  }
  return s[s.vendor].host;
};

export const prepareSourceWithForm: (
  s: Source,
  f: Form | undefined
) => PreparedSource = (source, form) => {
  const preparedSource: PreparedSource = {
    type: SourceType.PreparedSource,
    vendor: source.vendor,
    name: source.name,
  };

  if (!form) {
    return preparedSource;
  }

  const formFields = extractFormFields(form);
  formFields.forEach((field) => {
    const keys = field.prepared_source_path.split(".");
    let result = preparedSource;

    keys.forEach((key, idx) => {
      if (idx === keys.length - 1) {
        // No more keys to nest, set the value
        result[key] = prepareSourceField(source, field.name);
      } else {
        if (!result[key]) {
          result[key] = {};
        }
        result = result[key];
      }
    });
  });

  return preparedSource;
};

const extractFormFields: (f: Form) => SourceFormField[] = (form) => {
  return form.reduce((accumulator: SourceFormField[], { fields }) => {
    return [
      ...accumulator,
      ...fields.filter(({ name }) => !["service_account"].includes(name)),
    ];
  }, []);
};

const prepareSourceField: (
  d: Source,
  k: keyof Source
) =>
  | string
  | string[]
  | boolean
  | number
  | ServiceAccountKey
  | GcpExternalAccountMetadata
  | undefined = (source, fieldName) => {
  if (fieldName === "service_account_key") {
    return parseServiceAccountKey(source[fieldName]);
  } else if (fieldName === "workload_identity_federation_metadata") {
    return parseGcpIamRoleMetadata(source[fieldName]);
  } else if (fieldName === "port" || fieldName === "ssh_tunnel_port") {
    return parseInt(source[fieldName] ?? "0");
  } else {
    return source[fieldName];
  }
};

export const prepareSourceFromExisting: (e: ExistingSource) => Source = (
  existing
) => {
  const result: Source = {
    type: SourceType.Source,
    vendor: existing.vendor,
    name: existing.name,
  };

  return flatten(existing[existing.vendor as keyof ExistingSource], result);
};

const flatten = (vendor: any, result: Source): Source => {
  const isObject = (obj: any): obj is Object =>
    obj && typeof obj === "object" && !Array.isArray(obj);

  Object.keys(vendor).forEach((key) => {
    if (isObject(vendor[key])) {
      result = { ...result, ...flatten(vendor[key], result) };
    } else {
      result = {
        ...result,
        [key]: vendor[key],
      };
    }
  });
  return result;
};

export const computeChangedFields: (
  e: ExistingSource,
  s: PreparedSource
) => Partial<PreparedSource> = (existingSource, preparedSource) => {
  const isObject = (obj: any): obj is Object =>
    obj && typeof obj === "object" && !Array.isArray(obj);
  const arraysEqual = (a: any[], b: any[]): boolean => {
    if (a.length !== b.length) {
      return false;
    }

    for (let i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) {
        return false;
      }
    }

    return true;
  };

  const changedFields = {};
  Object.keys(preparedSource).forEach((key) => {
    if (key === "type" || key === "products") {
      // Don't continue if the existing destination does not have it, skip type and products keys
      return;
    }

    if (isObject(preparedSource[key]) && isObject(existingSource[key])) {
      const c = computeChangedFields(existingSource[key], preparedSource[key]);

      if (Object.entries(c).length > 0) {
        changedFields[key] = c;
      }
    } else if (
      Array.isArray(preparedSource[key]) &&
      Array.isArray(existingSource[key])
    ) {
      if (!arraysEqual(existingSource[key], preparedSource[key])) {
        changedFields[key] = preparedSource[key];
      }
    } else if (existingSource[key] !== preparedSource[key]) {
      changedFields[key] = preparedSource[key];
    }
  });

  return changedFields;
};

const DefaultSource: Source = {
  type: SourceType.Source,
  name: "",
  vendor: "snowflake",
};

export const getDefaultSource = () => ({
  ...DefaultSource,
});
