import React from "react";
import { Destination, FormField } from "@prequel/react";
import {
  FormField as FormFieldComponent,
  PasswordDot,
} from "@prequel-internal/react-components";
import { SourceFormField } from "../../../store/sources/form";
import { Source } from "../../../store/sources";
import { WrapperProps } from "..";

type InputWrapperProps<
  F extends FormField | SourceFormField,
  C extends Destination | Source
> = WrapperProps<F & { form_element: "input" }, C>;

const InputWrapper = <
  F extends FormField | SourceFormField,
  C extends Destination | Source
>({
  field,
  connection,
  setConnectionField,
  disabled,
  isEditing,
}: InputWrapperProps<F, C>) => {
  return (
    <FormFieldComponent
      label={field.label}
      id={field.name}
      type={field.input_type ?? "text"}
      subtext={field.description}
      placeholder={
        field.input_type === "password" && isEditing
          ? PasswordDot.repeat(20)
          : field.placeholder
      }
      overwriteOnly={field.input_type === "password" && isEditing}
      value={connection?.[field.name]?.toString() ?? ""}
      onChangeHandler={(value) =>
        setConnectionField(field.name as keyof C, value)
      }
      // If the field is required, make accommodations for editing
      // else the field is never required, return that value
      required={
        field.required
          ? (field.required && !isEditing) ||
            (isEditing && field.input_type !== "password")
          : field.required
      }
      disabled={disabled}
    />
  );
};

export default InputWrapper;
