import React from "react";
import { Button, ButtonStyle, Table } from "@prequel-internal/react-components";
import { useNavigate } from "react-router-dom";

import { ReactComponent as ShieldIcon } from "../../../assets/icons/shield.svg";
import ExistingRecipient, {
  ExistingSourceCredential,
} from "../../../store/recipients";
import ExistingSource from "../../../store/sources";

type SourceCredentialsTableProps = {
  sourceCredentials: ExistingSourceCredential[] | undefined;
  sources: ExistingSource[] | undefined;
  recipients: ExistingRecipient[] | undefined;
};
const SourceCredentialsTable = ({
  sourceCredentials,
  sources,
  recipients,
}: SourceCredentialsTableProps) => {
  const navigate = useNavigate();

  const rows = [...(sourceCredentials || [])].map((credential) => {
    const source = sources?.find(({ id }) => id === credential.source_id);
    const recipient = recipients?.find(
      ({ id }) => id === credential.recipient_id
    );
    return {
      id: credential.id,
      cells: [
        {
          value: recipient ? recipient.name : "Not Found",
        },
        {
          value: source ? source.name : "Not Found",
        },
        { value: credential.username },
      ],
    };
  });

  const columns = ["Recipient Name", "Source Name", "Username"];

  if (rows.length === 0) {
    return (
      <div className="flex-col bg-gray-50 border border-gray-200 text-gray-500 rounded p-4 text-sm">
        <div className="flex">
          <ShieldIcon
            className="h-12 w-12 text-emerald-600"
            aria-hidden="true"
          />
          <div className="ml-3">
            <p className="font-medium text-gray-900 mb-1">
              {
                "Recipient source credentials is not enabled on your account, talk to support if you'd like to use this feature."
              }
            </p>
            <p>
              Recipient source credentials can be used when a data source uses
              role-based-access-control and a unique set of credentials must be
              used per source-recipient pair. Using recipient source credentials
              is only recommended if your source requires RBAC.{" "}
              <a
                target="_blank"
                href="https://docs.prequel.co/reference/get_recipient-source-credentials"
                rel="noreferrer"
                className="font-medium text-primary-600 hover:text-primary-500"
              >
                Learn more
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="flex justify-end pb-4">
        <Button
          text="Add Source Credential"
          onClick={() => navigate("new")}
          type={ButtonStyle.TERTIARY}
        />
      </div>
      <Table
        columns={columns}
        rows={rows}
        footer={rows ? `${rows.length} credentials` : ""}
      />
    </>
  );
};

export default SourceCredentialsTable;
