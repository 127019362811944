import React from "react";

import { useRoutes, useNavigate, NavLink } from "react-router-dom";
import {
  PageHeading,
  Tabs,
  checkTabIsActive,
  Button,
  ButtonStyle,
} from "@prequel-internal/react-components";

import SourceForm from "./SourceForm";
import SourcePage from "./SourcePage";
import SourcesCards from "./SourcesCards";

const FirstPartyConnectionsPage = () => {
  const navigate = useNavigate();

  const tabs = [
    <NavLink key="Summary" to="#" className={checkTabIsActive}>
      Summary
    </NavLink>,
  ];

  const element = useRoutes([
    {
      path: "/",
      element: (
        <div className="max-w-6xl pb-16">
          <PageHeading title={"Sources"}>
            <Button
              text={"Add Source"}
              onClick={() => navigate("new")}
              type={ButtonStyle.SECONDARY}
            />
          </PageHeading>
          <Tabs tabs={tabs} />
          <SourcesCards />
        </div>
      ),
    },
    {
      path: "new",
      element: (
        <div className="max-w-lg">
          <PageHeading title="Add Source" onGoBack={() => navigate(-1)} />
          <SourceForm />
        </div>
      ),
    },
    {
      path: ":id/edit",
      element: (
        <div className="max-w-lg">
          <PageHeading title="Edit Source" onGoBack={() => navigate(-1)} />
          <SourceForm />
        </div>
      ),
    },
    {
      path: ":id/*",
      element: (
        <div className="max-w-4xl">
          <SourcePage />
        </div>
      ),
    },
  ]);

  return <div className="flex flex-col py-4 pb-4 px-7">{element}</div>;
};

export default FirstPartyConnectionsPage;
